import React, { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { BiCheckDouble } from "react-icons/bi";
import { CgFileDocument } from "react-icons/cg";
import { FaCheck } from "react-icons/fa6";
import { FiChevronsRight } from "react-icons/fi";
import { MdAccountCircle, MdOutlineChevronRight } from "react-icons/md";
import { TbArrowsExchange2 } from "react-icons/tb";
import { Oval } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  createDocumentDraftAPI,
  getFlowStatusAPI,
  getInitialInformationAPI,
  getPlanReviewChecklistAPI,
  makeFlowPlanAPI,
  updateClauseAPI,
} from "../api/apiCalls";
import { Modal } from "../components/Modal";
import { EachReviewItem } from "../components/Steps/EachReviewItem";
import { GeneralInputStep } from "../components/Steps/GeneralInputStep";
import { NextStepButton } from "../components/Steps/NextStepButton";
import { flowProcessStatuses } from "../data";
import { updateDocument } from "../redux/actions/documentActions";
import { pushToSteps, updateStepper } from "../redux/actions/stepperActions";
import { ChatWithAI } from "./Chat";
import { IoWarning } from "react-icons/io5";

export const CreateDocument = () => {
  const navigate = useNavigate();

  const params = useParams();
  const [searchParams] = useSearchParams();

  // const currentStep = parseInt(searchParams.get("step"));
  const dispatch = useDispatch();

  const {
    steps,
    mainStep,
    stepTitles,
    currentStep,
    loading,
    flowStatus,
    reviewItems,
    documentDetails,
  } = useSelector((state) => state.stepper);
  const { context, location } = useSelector((state) => state.document);

  const changeCurrentStep = (value, passedStep) => {
    // ? not using this
    // // //currentStep, steps.length);
    // switch (value) {
    //   case "next":
    //     if (currentStep === steps.length) {
    //       return navigate(`/document/${params.id}/draft`);
    //     } else if (currentStep !== steps.length) {
    //       return navigate(
    //         `/document/${params.id}/create?step=${currentStep + 1}`
    //       );
    //     }
    //     break;
    //   case "previous":
    //     if (currentStep <= 1) {
    //       navigate(`/document/${params.id}`);
    //     } else
    //       navigate(`/document/${params.id}/create?step=${currentStep - 1}`);
    //     break;
    //   case "specific_page":
    //     navigate(`/document/${params.id}/create?step=${passedStep}`);
    //     break;
    //   default:
    //     break;
    // }
  };

  function convertStringToObject(inputString) {
    const legalTitleRegex = /Legal\s*title:\s*(.+)/i;
    const sectionTitleRegex = /Section\s*title:\s*(.+)/i;
    const sectionDescriptionRegex = /Section\s*description:\s*(.+)/i;
    const titleRegex = /Question:\s*(.+)/i;
    const exampleRegex = /Example:\s*(.+)/i;
    const answersRegex = /Answers:/i;
    const answerRegexWithTitle = /\d+\.\s*([^,:]+)[,:]\s*(.+)/;
    const answerRegexWithoutTitle = /\d+\.\s*(.+)/;

    const outputObject = {
      legal: {},
      section: {},
      answers: [],
    };

    let currentSection = null;

    inputString.split("\n").forEach((line) => {
      let matches;
      if ((matches = line.match(legalTitleRegex))) {
        outputObject.legal.title = matches[1].trim();
      } else if ((matches = line.match(sectionTitleRegex))) {
        outputObject.section.title = matches[1].trim();
      } else if ((matches = line.match(sectionDescriptionRegex))) {
        outputObject.section.description = matches[1].trim();
      } else if ((matches = line.match(titleRegex))) {
        outputObject.title = matches[1].trim();
      } else if ((matches = line.match(exampleRegex))) {
        outputObject.example = matches[1].trim();
      } else if (line.match(answersRegex)) {
        currentSection = "answers";
      } else if (currentSection === "answers") {
        const answerMatchWithTitle = line.match(answerRegexWithTitle);
        const answerMatchWithoutTitle = line.match(answerRegexWithoutTitle);

        if (answerMatchWithTitle) {
          const title = answerMatchWithTitle[1].trim();
          const description = answerMatchWithTitle[2].trim();
          outputObject.answers.push({ title, description });
        } else if (answerMatchWithoutTitle) {
          const description = answerMatchWithoutTitle[1].trim();
          const title = `Suggestion ${outputObject.answers.length + 1}`;
          outputObject.answers.push({ title, description });
        }
      }
    });

    return outputObject;
  }

  const makeFlowPlan = async (onSuccess) => {
    dispatch(
      updateStepper({
        loading: true,
      })
    );

    document.title = "🟠 Airstrip AI - Review (2/3)";

    dispatch(
      updateStepper({
        flowStatus: flowProcessStatuses.PLAN_PROCESSING,
      })
    );

    try {
      const makeFlowPlanResponse = makeFlowPlanAPI({
        flowID: params.id,
      });

      if (onSuccess) {
        onSuccess();
        dispatch(
          updateStepper({
            loading: false,
          })
        );
      }
    } catch (error) {
      console.log("error in make flow plan");
    }
  };

  const { token, errorModal } = useSelector((state) => state.user);
  const streamDocumentProcess = async () => {
    // console.log("steps", steps);
    // console.log("current step: ", steps[currentStep]);
    // console.log("current step: ", currentStep);

    document.title = "🟠 Airstrip AI - Details (1/3)";

    dispatch(
      updateDocument({
        creatingProcess: true,
      })
    );

    const bodyObject =
      currentStep === 1
        ? {
            flowID: params.id,
          }
        : {
            flowID: params.id,
            message: steps[currentStep - 2].inputs.value,
          };

    const response = await getInitialInformationAPI(bodyObject);

    // console.log("------");
    // console.log("previous step object: ", steps[currentStep - 2]);
    // console.log("current step object: ", steps[currentStep - 1]);
    // console.log("------");

    // // //response);

    // console.log("response: ", response);

    if (response.data) {
      if (response.data.message) {
        dispatch(
          updateDocument({
            creatingProcess: false,
          })
        );

        dispatch(
          pushToSteps({
            title: response.data.message,
          })
        );
      }

      if (
        response.data.success &&
        response.data.status ===
          flowProcessStatuses.INITIAL_INFORMATION_COMPLETED &&
        mainStep !== 2 &&
        flowStatus !== flowProcessStatuses.INITIAL_INFORMATION_COMPLETED
      ) {
        dispatch(
          updateStepper({
            flowStatus: flowProcessStatuses.INITIAL_INFORMATION_COMPLETED,
          })
        );

        // the initial information is collected and it moves to the next step
        // to start preselecting the main information and loading
        // api call to start making flow plan process
        try {
          makeFlowPlan();
        } catch (error) {
          // console.log("error in make flow plan");
        }

        // console.log("running from stream document process");

        dispatch(
          updateDocument({
            creatingProcess: false,
          })
        );

        dispatch(
          updateStepper({
            mainStep: 2,
          })
        );
      }
    }
  };

  // no needed as no streaming
  let text = "";

  async function readStream(stream) {
    const reader = stream.getReader();
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        // // console.log("***Finish Completed Object***", incompleteObject);

        const outputObject = convertStringToObject(incompleteObject);

        completeObjects.push(outputObject);
        dispatch(pushToSteps(outputObject));

        // // console.log("FULL ARRAY", completeObjects);

        // // console.log("TEXT: ", text);

        dispatch(
          updateDocument({
            creatingProcess: false,
          })
        );
        return;
      }

      const textDecoder = new TextDecoder();
      try {
        const object = JSON.parse(textDecoder.decode(new Uint8Array(value)));

        if (object.data?.delta) {
          processStreamedData(object.data?.delta?.content);

          // // //object.data?.delta?.content);
          //
        }
      } catch {
        const input = `${textDecoder.decode(new Uint8Array(value))}`;

        const objects = input
          .trim()
          .split("\n")
          .map((str) => {
            const object = JSON.parse(str);

            if (object.data?.delta) {
              processStreamedData(object.data?.delta?.content);

              //
            }
          });
      }
    }
  }

  // no needed as no streaming
  let incompleteObject = null;
  const completeObjects = [];

  function processStreamedData(chunk) {
    // console.log("Chunk:", `^${chunk}^`);

    text += chunk;

    if (chunk.includes("~") && incompleteObject === null) {
      // // //"*****Object started***");
      incompleteObject = " ";
      return;
    }

    if (chunk.includes("~") && incompleteObject !== null) {
      // console.log("***Finish Completed Object***", incompleteObject);

      const outputObject = convertStringToObject(incompleteObject);
      // console.log(outputObject);

      completeObjects.push(outputObject);
      dispatch(pushToSteps(outputObject));

      // console.log("FULL ARRAY", completeObjects);

      incompleteObject = " ";
      return;
    }

    if (incompleteObject) {
      incompleteObject += chunk;
      return;
    }
  }

  const runFunctionsBasedOnFlowStatus = async (currentFlowStatus) => {
    dispatch(
      updateStepper({
        loading: true,
      })
    );
    const status = currentFlowStatus ?? (await getFlowStatus());

    // console.log("CURRENT FLOW STATUS: ", currentFlowStatus);
    // console.log("FLOW STATUS: ", flowStatus);

    if (status) {
      dispatch(
        updateStepper({
          loading: false,
        })
      );
    }

    switch (status) {
      case flowProcessStatuses.CREATED_FLOW:
        // console.log("created flow");

        dispatch(
          updateStepper({
            mainStep: 1,
          })
        );

        if (!steps || (steps?.length === 0 && errorModal !== true)) {
          streamDocumentProcess();
        }
        break;
      case flowProcessStatuses.INITIAL_INFORMATION_PROCESSING:
        // console.log("initial information");

        dispatch(
          updateStepper({
            mainStep: 1,
          })
        );

        if (!steps || (steps?.length === 0 && errorModal !== true)) {
          streamDocumentProcess();
        }
        break;
      case flowProcessStatuses.INITIAL_INFORMATION_COMPLETED:
        dispatch(
          updateStepper({
            mainStep: 1,
          })
        );
        if (flowStatus !== flowProcessStatuses.PLAN_PROCESSING) {
          // console.log("running from switch");

          try {
            makeFlowPlan();
          } catch (error) {
            // console.log("error in make flow plan");
          }

          dispatch(
            updateStepper({
              mainStep: 2,
              flowStatus: flowProcessStatuses.PLAN_PROCESSING,
              loading: false,
            })
          );
        }
        break;
      case flowProcessStatuses.PLAN_PROCESSING:
        // makeFlowPlan();

        dispatch(
          updateStepper({
            mainStep: 2,
            flowStatus: flowProcessStatuses.PLAN_PROCESSING,
            loading: false,
          })
        );

        dispatch(
          updateDocument({
            creatingProcess: false,
          })
        );

        break;
      case flowProcessStatuses.REVIEW_NEEDED:
        document.title = "🟠 Airstrip AI - Finalise (3/3)";

        var originalTitle = document.title;
        var notificationInterval;

        function startNotification() {
          // Set an interval to change the document title only when the page is hidden
          notificationInterval = setInterval(function () {
            if (document.visibilityState === "hidden") {
              document.title =
                document.title === originalTitle
                  ? "(1) 🟠 Airstrip AI - Finalise (3/3)"
                  : originalTitle;
            }
          }, 1000); // Change the title every 1 second, adjust as needed
        }

        function stopNotification() {
          // Clear the interval and reset the document title
          clearInterval(notificationInterval);
          document.title = originalTitle;
        }

        // Example: Trigger startNotification when your step is finished
        startNotification();

        // Example: Trigger stopNotification when the user interacts with the site
        window.addEventListener("focus", function () {
          stopNotification();
        });

        dispatch(
          updateStepper({
            mainStep: 3,
          })
        );

        getFlowPlan();
        break;
      case flowProcessStatuses.REVIEW_COMPLETED:
        // TODO: SHOW THAT DOCUMENT IS BEING GENERATED
        break;
      case flowProcessStatuses.DRAFT_COMPLETED:
        // TODO: SHOW THAT DOCUMENT IS ALREADY GENERATED AND REDIRECT TO MY DOCUMENTS
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (currentStep !== 1 && mainStep === 1) {
      // console.log("running from useeffect");
      dispatch(
        updateDocument({
          creatingProcess: true,
        })
      );
      streamDocumentProcess();
    }
  }, [currentStep]);

  const getFlowStatus = async (onSuccess) => {
    // TODO: SEND THE FLOW ID FROM THE PARAMS PASSED FROM THE HOME PAGE - UNCOMMENT THESE
    const response = await getFlowStatusAPI({ flowID: params.id });
    dispatch(
      updateStepper({
        flowStatus: response.data.status,
      })
    );

    if (onSuccess && response.data) onSuccess(response.data.status);

    return response.data.status;
  };

  useEffect(() => {
    if (mainStep === 2) {
      runFunctionsBasedOnFlowStatus(flowStatus);
    }
  }, [flowStatus]);

  useEffect(() => {
    getFlowStatus((status) => runFunctionsBasedOnFlowStatus(status));
    // runFunctionsBasedOnFlowStatus();

    // have 3 routes: /details, /review, /confirm
    // push to these routes based on the step
    // no need

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    // Run the function initially
    // TODO: getFlowStatus();
  }, []);

  useEffect(() => {
    if (mainStep === 2) {
      // Set up the interval
      const intervalId = setInterval(() => {
        getFlowStatus();
      }, 15000);

      // Clean up the interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [mainStep]);

  const getFlowPlan = async () => {
    const response = await getPlanReviewChecklistAPI({ flowID: params.id });

    if (response.data) {
      dispatch(
        updateStepper({
          reviewItems: response.data,
        })
      );
    }
  };

  const [openedConfirmBoxes, setOpenedConfirmBoxes] = useState([]);

  const toggleOpenedBox = (index) => {
    if (openedConfirmBoxes.includes(index)) {
      const newArray = openedConfirmBoxes.filter(
        (newIndex) => newIndex !== index
      );
      setOpenedConfirmBoxes(newArray);
    } else {
      setOpenedConfirmBoxes([...openedConfirmBoxes, index]);
    }
  };

  const isBoxOpened = (index) => {
    return openedConfirmBoxes.includes(index);
  };

  const localConfirmArray = [
    {
      title: "What to do if your customer asks for a refund?",
      decision:
        "You will give 100% refund together with a correct version of the product, shipped with free delivery to the user.",
      accepted: true,
      reason:
        "The user has provided information about the pricing model, but it's not complete. I need to know the specifics of the monthly subscription and the payment methods they accept.",
    },
    {
      title: "What to do if your customer asks for a refund?",
      decision:
        "You will give 100% refund together with a correct version of the product, shipped with free delivery to the user.",
      accepted: true,
      reason:
        "The user has provided information about the pricing model, but it's not complete. I need to know the specifics of the monthly subscription and the payment methods they accept.",
    },
    {
      title: "What to do if your customer asks for a refund?",
      decision:
        "You will give 100% refund together with a correct version of the product, shipped with free delivery to the user.",
      accepted: true,
      reason:
        "The user has provided information about the pricing model, but it's not complete. I need to know the specifics of the monthly subscription and the payment methods they accept.",
    },
    {
      title: "What to do if your customer asks for a refund?",
      decision:
        "You will give 100% refund together with a correct version of the product, shipped with free delivery to the user.",
      accepted: true,
      reason:
        "The user has provided information about the pricing model, but it's not complete. I need to know the specifics of the monthly subscription and the payment methods they accept.",
    },
    {
      title: "What to do if your customer asks for a refund?",
      decision:
        "You will give 100% refund together with a correct version of the product, shipped with free delivery to the user.",
      accepted: true,
      reason:
        "The user has provided information about the pricing model, but it's not complete. I need to know the specifics of the monthly subscription and the payment methods they accept.",
    },
    {
      title: "What to do if your customer asks for a refund?",
      decision:
        "You will give 100% refund together with a correct version of the product, shipped with free delivery to the user.",
      accepted: true,
      reason:
        "The user has provided information about the pricing model, but it's not complete. I need to know the specifics of the monthly subscription and the payment methods they accept.",
    },
  ];

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [selectedClauseID, setSelectedClauseID] = useState(null);
  const [messages, setMessages] = useState([]);
  const [updateClauseMessage, setUpdateClauseMessage] = useState("");
  const [showUpdateClauseSuccess, setShowUpdateClauseSuccess] = useState(false);

  const [showingClauseInfo, setShowingClauseInfo] = useState(0);

  const onModalClose = () => {
    setShowUpdateModal(false);
    setUpdateLoading(false);
    setSelectedClauseID(null);
    setMessages([]);
    setUpdateClauseMessage("");
  };

  let localMessages = [...messages];

  const sendUpdateMessage = async (e, textValue = updateClauseMessage) => {
    setUpdateLoading(true);

    if (e) e.preventDefault();

    localMessages.push({
      role: "user",
      content: textValue || updateClauseMessage,
    });

    setMessages(localMessages);

    setUpdateClauseMessage("");

    const response = await updateClauseAPI({
      flowID: params.id,
      clauseID: selectedClauseID,
      messages: localMessages,
    });

    if (response.data) {
      document.getElementById("updateClauseLoading").scrollIntoView();

      if (response.data.success && response.data.status === "DONE") {
        let newReviewItems = reviewItems?.map((eachReviewItem) => {
          if (eachReviewItem._id === selectedClauseID) {
            // // console.log(`CHANGING ${JSON.stringify(eachReviewItem)}`);

            return {
              ...eachReviewItem,
              reason: response.data.updatedInformation.reason,
              decision: response.data.updatedInformation.decision,
            };
          } else return eachReviewItem;
        });

        // // console.log("NEW REVIEW ITEMS: ", newReviewItems);

        dispatch(
          updateStepper({
            reviewItems: newReviewItems,
          })
        );

        setShowUpdateClauseSuccess(true);

        setTimeout(() => {
          setShowUpdateClauseSuccess(false);
          setShowUpdateModal(false);
          setUpdateLoading(false);
          setSelectedClauseID(null);
          setMessages([]);
          setUpdateClauseMessage("");
        }, 3000);
      } else {
        setUpdateLoading(false);
        localMessages.push({
          role: "assistant",
          content: response.data.message,
        });
        setMessages(localMessages);
      }
    }
  };

  const selectedClauseInfo = () => {
    return reviewItems?.filter(
      (eachReviewItem) => eachReviewItem._id === selectedClauseID
    )[0];
  };

  const toggleReviewItems = (type) => {
    switch (type) {
      case "next":
        if (
          showingClauseInfo >= 0 &&
          showingClauseInfo < reviewItems?.length - 1
        ) {
          if (!isBoxOpened(showingClauseInfo + 2)) {
            toggleOpenedBox(showingClauseInfo + 2);
          }

          setShowingClauseInfo((prev) => prev + 1);
        }
        break;
      case "previous":
        if (showingClauseInfo > 0) {
          // toggleOpenedBox(showingClauseInfo - 2);
          if (!isBoxOpened(showingClauseInfo - 2)) {
            toggleOpenedBox(showingClauseInfo - 2);
          }
          setShowingClauseInfo((prev) => prev - 1);
        }
      default:
        break;
    }
  };

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [createDraftLoading, setCreateDraftLoading] = useState(false);
  const [createDocumentError, setCreateDocumentError] = useState(null);

  const confirmAndCreateDocument = async () => {
    if (!showConfirmModal) {
      setShowConfirmModal(true);
    } else {
      // create draft api
      setCreateDraftLoading(true);

      // // console.log(documentDetails);

      try {
        const response = await createDocumentDraftAPI({
          context: context,
          jurisdiction:
            location &&
            location.country &&
            `${location.state}, ${location.country}`,
          data: [],
          flowID: params.id,
        });
        if (response.data) {
          setCreateDraftLoading(false);

          navigate(`/document/${response.data.draft_id}/draft`);

          dispatch(
            updateStepper({
              currentStep: 1,
              steps: [],
              warnings: [],
              mainStep: 1,
              reviewItems: [],
              flowStatus: "",
              loading: false,
            })
          );
        }
      } catch (error) {
        setCreateDocumentError(error.response.data.message);
        setCreateDraftLoading(false);
      }
    }
  };

  const [showMore, setShowMore] = useState(false);

  return (
    <div
      className="createDocumentDetail createDocumentContainer"
      id="createDocumentContainer"
    >
      {/* {JSON.stringify(steps)} */}

      {/* {JSON.stringify(flowStatus)}
      {JSON.stringify(loading)}
      {JSON.stringify(mainStep)}
      {JSON.stringify(currentStep)} */}

      {/* {JSON.stringify(steps)} */}

      {showConfirmModal && (
        <Modal
          modalClassName="confirmModal updateClauseModal"
          onClose={() => {
            setShowConfirmModal(false);
          }}
          HeaderIcon={() => <BiCheckDouble fontSize={28} color="#1253f3" />}
          // headerText={"Provide some Information to create your process"}
          headerText={"Final confirmation"}
          description="Confirm before generating the document"
          onClick={() => {
            //
          }}
          loading={updateLoading}
          buttonText="Create"
          headerClassName="explainBusinessModalHeader"
          showButtonContainer={false}
          showButtonArrow={true}
        >
          <p className="confirmModalParagraph">
            Before we turn your decisions into a document, please confirm if
            these details match your business interests. Clicking on create,
            will start drafting the document using these decisions.
          </p>

          {createDocumentError && (
            <p
              className="errorText"
              style={{
                marginLeft: "20px",
                marginBottom: "10px",
                width: "93%",
                backgroundColor: "#FF000010",
                padding: "15px",
                borderRadius: "10px",
                fontWeight: "500",
                display: "flex",
                paddingBottom: "20px",
              }}
            >
              <IoWarning fontSize={20} />
              <div style={{ marginLeft: "15px" }}>
                <p style={{ marginBottom: "5px", lineHeight: "1.6em" }}>
                  {createDocumentError}
                  {/* You have already created a document using this flow, you can
                  view it your documents screen. */}
                </p>
                <p
                  style={{
                    marginBottom: "15px",
                    fontSize: "14px",
                    opacity: "0.7",
                    fontWeight: "400",
                  }}
                >
                  Don't worry, all this will be saved and can be access in the
                  home screen under 'Pending flows'
                </p>
                <Link
                  to="/pricing"
                  style={{
                    marginRight: "10px",
                    textDecoration: "none",
                  }}
                >
                  Upgrade to Pro
                </Link>
                <Link to="/" style={{ textDecoration: "none" }}>
                  Create new Document
                </Link>
              </div>
            </p>
          )}

          <div className="confirmModalButtons">
            <div
              className="confirmCancelButton"
              style={{
                opacity: 0.4,
              }}
              onClick={() => setShowConfirmModal(false)}
            >
              Cancel
            </div>
            <button
              className={`confirmCreateButton ${
                createDraftLoading && "disabledButton"
              }`}
              disabled={createDraftLoading}
              onClick={() => confirmAndCreateDocument()}
            >
              <p>Yes, Create It </p>
              <MdOutlineChevronRight style={{ fontSize: 20 }} />
            </button>
          </div>
        </Modal>
      )}

      {showUpdateModal && (
        <Modal
          modalClassName="updateClauseModal"
          onClose={() => {
            onModalClose();
          }}
          HeaderIcon={() => <TbArrowsExchange2 fontSize={25} color="#1253f3" />}
          // headerText={"Provide some Information to create your process"}
          headerText={"Want to make changes to this decision?"}
          description="Double-check your decision with AI. Enter your changes below."
          onClick={() => {
            //
          }}
          loading={updateLoading}
          buttonText="Prepare Document"
          headerClassName="explainBusinessModalHeader"
          showButtonContainer={false}
          showButtonArrow={true}
        >
          <div className="updateClauseModalContent">
            {showUpdateClauseSuccess && (
              <div className="updateClauseSuccess">
                <FaCheck className="updateClauseSuccessIcon" />
                <p className="updateClauseText">The Decision is updated!</p>
              </div>
            )}

            {/* here{updateClauseMessage} */}
            <div className="updateClauseChats">
              <div className="updateClauseEmptyChats">
                <FaCheck className="updateClauseEmptyChatsIcon" />

                <div className="updateClauseEmptyChatsDecision">
                  <p
                    style={{
                      fontSize: "16px",
                      marginBottom: 5,
                      fontWeight: "600",
                    }}
                  >
                    {selectedClauseInfo().question}
                  </p>
                  <p>Current Decision: {selectedClauseInfo().decision}</p>
                </div>
              </div>

              {messages.length !== 0 && (
                <div className="updateClauseChatsContainer">
                  {messages.map((eachMessage) => (
                    <div
                      className={`updateClauseEachChat ${
                        eachMessage.role === "user"
                          ? "userChat"
                          : "assistantChat"
                      }`}
                    >
                      {eachMessage.role === "user" ? (
                        <MdAccountCircle
                          fontSize={30}
                          className="updateClauseEachChatIcon"
                        />
                      ) : (
                        <img
                          src="/logowhiteblue.png"
                          className="imageIcon"
                          style={{
                            marginLeft: "0px",
                            width: "30px",
                            height: "30px",
                          }}
                        />
                      )}

                      <div className="updateClauseEachChatMain">
                        <div className="updateClauseEachChatType">
                          {eachMessage.role === "user" ? "You" : "Assistant"}
                        </div>
                        <p className="updateClauseEachChatText">
                          {eachMessage.content}
                        </p>
                      </div>
                    </div>
                  ))}

                  {updateLoading && (
                    <div
                      className={`updateClauseEachChat assistantChat updateClauseLoading`}
                      id="updateClauseLoading"
                    >
                      <img
                        src="/logowhiteblue.png"
                        className="imageIcon"
                        style={{
                          marginLeft: "0px",
                          width: "30px",
                          height: "30px",
                        }}
                      />
                      <div className="updateClauseEachChatMain">
                        <div className="updateClauseEachChatType">
                          Assistant
                        </div>
                        <p
                          className="updateClauseEachChatText"
                          style={{
                            fontStyle: "italic",
                            opacity: 0.5,
                            fontSize: 15,
                          }}
                        >
                          Let me help you find the best fit...
                        </p>

                        <Oval
                          height={20}
                          width={20}
                          color="#1252f3"
                          wrapperStyle={{}}
                          visible={true}
                          ariaLabel="oval-loading"
                          secondaryColor="#1252f320"
                          wrapperClass="updateClauseLoadingIcon"
                          strokeWidth={5}
                          strokeWidthSecondary={5}
                        />
                      </div>
                    </div>
                  )}

                  {/* <div className={`updateClauseEachChat assistantChat`}>
                  <img
                    src="/logowhiteblue.png"
                    className="imageIcon"
                    style={{ marginLeft: "0px", width: "30px", height: "30px" }}
                  />
                  <div className="updateClauseEachChatMain">
                    <div className="updateClauseEachChatType">Assistant</div>
                    <p className="updateClauseEachChatText">
                      Ok great, I'll add that.
                    </p>
                  </div>
                </div> */}
                </div>
              )}
            </div>
            <form
              onSubmit={(e) => sendUpdateMessage(e)}
              className="signupFlowQuestionContainer updateClauseInputContainer"
            >
              <p className="signupFlowQuestionLabel">Enter your new changes</p>
              <input
                type="text"
                placeholder="What changes do you want to make?"
                className="updateClauseInput signupFlowQuestionInput"
                value={updateClauseMessage}
                onChange={(e) => setUpdateClauseMessage(e.target.value)}
                autoFocus
              />
              <button
                type="submit"
                className={`updateClauseButton ${
                  updateLoading && "disabledButton"
                }`}
                disabled={updateLoading}
                onClick={(e) => sendUpdateMessage(e)}
              >
                <p>Make Changes</p>{" "}
                {updateLoading && (
                  <Oval
                    height={15}
                    width={15}
                    color="white"
                    wrapperStyle={{}}
                    wrapperClass="modalMainContentButtonLoader"
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#ffffff7b"
                    strokeWidth={5}
                    strokeWidthSecondary={5}
                  />
                )}
                {/* <IoIosSend className="updateClauseButtonIcon" /> */}
              </button>
            </form>
            {/* <div className="updateClauseInputSuggestions">
              <div
                className="updateClauseEachSuggestion"
                onClick={() =>
                  sendUpdateMessage(
                    null,
                    "What are some other possible options?"
                  )
                }
              >
                What are some other possible options?
              </div>
              <div
                className="updateClauseEachSuggestion"
                onClick={() =>
                  sendUpdateMessage(null, "What is right for my business?")
                }
              >
                What is right for my business?
              </div>
            </div> */}
          </div>
        </Modal>
      )}

      {loading ? (
        <div className="flowStatusLoadingContainer">
          <Oval
            height={40}
            width={40}
            color="#1252f3"
            wrapperStyle={{}}
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#1252f320"
            strokeWidth={5}
            strokeWidthSecondary={5}
          />
        </div>
      ) : (
        <>
          {window.location.href.indexOf("chat") > -1 && <ChatWithAI />}

          <div className="createDocumentStepper">
            {mainStep === 3 && (
              <div
                className="confirmAndCreateDocument"
                onClick={() => confirmAndCreateDocument()}
              >
                <p className="confirmAndCreateText">
                  Confirm and Generate Document
                </p>
                <FiChevronsRight className="confirmAndCreateIcon" />

                <div className="brandNewAnimation"></div>
              </div>
            )}

            <div className="createDocumentStepperLeft">
              <img src="/logowhiteblue.png" className="imageIcon" />
              <CgFileDocument className="imageIcon" />
            </div>
            <div className="createDocumentStepperCenter">
              <div
                className={`createDocumentEachStep ${
                  mainStep === 1 && "currentDocumentEachStep"
                }`}
              >
                <p className="createDocumentStepNumber">1</p>
                <p className="createDocumentStepName">Details</p>
              </div>
              <div
                className={`createDocumentEachStep ${
                  mainStep === 2 && "currentDocumentEachStep"
                }`}
              >
                <p className="createDocumentStepNumber">2</p>
                <p className="createDocumentStepName">Review</p>
              </div>
              <div
                className={`createDocumentEachStep ${
                  mainStep === 3 && "currentDocumentEachStep"
                }`}
              >
                <p className="createDocumentStepNumber">3</p>
                <p className="createDocumentStepName">Finalise</p>
              </div>
            </div>

            {/* {currentStep}
          {stepTitles.length} */}

            {/* {mainStep === 1 && (
            <div
              className={`createDocumentStepperProgress`}
              style={{
                width: `${(currentStep / stepTitles.length) * 100}%`,
              }}
            ></div>
          )} */}
          </div>

          {mainStep === 1 ? (
            <>
              {/* <div className="createDocumentDetailLeft">
          <IoArrowBack
            className="documentDetailBackIcon"
            onClick={() => {
              changeCurrentStep("previous");
            }}
          />
  
          {currentStep === 0 ? (
            // eslint-disable-next-line jsx-a11y/alt-text
            <img
              src={createDocumentSkeleton}
              className="createDocumentDetailSkeleton"
            />
          ) : (
            <SideStepper changeCurrentStep={changeCurrentStep} />
          )}
        </div> */}

              {/* {currentStep === 1 ? <DetailedInputStep /> : <GeneralInputStep />} */}
              <GeneralInputStep />

              {steps && steps[currentStep - 1] && <NextStepButton />}
            </>
          ) : mainStep === 2 ? (
            <div className="createDocumentReviewStep">
              <p>
                You can now safely close your browser, we will send you an email
                notification once it's done.
              </p>
              <Oval
                height={40}
                width={40}
                color="#1252f3"
                wrapperStyle={{}}
                wrapperClass="createDocumentReviewLoading"
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#1252f320"
                strokeWidth={5}
                strokeWidthSecondary={5}
              />
              <p
                className="createDocumentReviewStepDescription"
                style={{ fontStyle: "italic" }}
              >
                Estimated time: 10-15 mins
              </p>
              <h1 className="createDocumentReviewStepHeader">
                Preparing a Legal Review of your business
              </h1>
              <p className="createDocumentReviewStepDescription">
                Our AI is reviewing your business, preselecting the best options
                to match legal industry standards biased to your business.
                {/* Our AI is */}
                {/* <br /> */}
                {/* <li>Reviewing your business</li>
              <li>Preselecting best options</li>
              <li>Matching industry standards</li>
              <li>Biased to your business</li> */}
                {/* and be  */}
              </p>
              <p className="createDocumentReviewStepLabel">
                You can finally review and confirm the changes.
              </p>
            </div>
          ) : (
            <div className="createDocumentConfirmStep">
              <h1 className="createDocumentReviewStepHeader">Finalise</h1>
              <p
                className="createDocumentReviewStepLabel"
                style={{
                  marginTop: "5px",
                  width: "590px",
                  textAlign: "center",
                  fontSize: "15px",
                  lineHeight: "1.5em",
                }}
              >
                Expand on each section and check if the selected details aligns
                with your business interests and requirements. Make any
                necassary changes if you want to, If you are comfortable with
                the decisions, click on Confirm and Generate the document.
              </p>

              {/* {JSON.stringify(reviewItems)} */}

              {reviewItems?.length === 0 ? (
                <>
                  <Oval
                    height={40}
                    width={40}
                    color="#1252f3"
                    wrapperStyle={{}}
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#1252f320"
                    strokeWidth={5}
                    strokeWidthSecondary={5}
                  />
                </>
              ) : (
                <div className="createDocumentConfirmDetails">
                  {reviewItems?.slice(0, 3).map((eachReviewItem, index) => (
                    <EachReviewItem
                      toggleOpenedBox={toggleOpenedBox}
                      index={index}
                      eachReviewItem={eachReviewItem}
                      setShowUpdateModal={setShowUpdateModal}
                      isBoxOpened={isBoxOpened}
                      setSelectedClauseID={setSelectedClauseID}
                    />
                  ))}
                </div>
              )}

              <div className="reviewItemsDividerContainer">
                <div className="reviewItemsDivider"></div>
                <div
                  className={`reviewItemsShowMore ${
                    showMore ? "showMore" : "showLess"
                  }`}
                  onClick={() => setShowMore(!showMore)}
                >
                  Show {showMore ? "less" : "more"} (
                  {reviewItems?.slice(3, reviewItems?.length).length})
                </div>
              </div>

              {showMore && (
                <div className="createDocumentConfirmDetails">
                  {reviewItems
                    ?.slice(3, reviewItems?.length)
                    .map((eachReviewItem, index) => (
                      <EachReviewItem
                        toggleOpenedBox={toggleOpenedBox}
                        index={index + 3}
                        eachReviewItem={eachReviewItem}
                        setShowUpdateModal={setShowUpdateModal}
                        isBoxOpened={isBoxOpened}
                        setSelectedClauseID={setSelectedClauseID}
                      />
                    ))}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};
